import { UtilsService } from '../../../core/utils-service/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-otpdialog',
  templateUrl: './otpdialog.component.html',
  styleUrls: ['./otpdialog.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class OtpdialogComponent implements OnInit, OnDestroy {
  readonly OTP_LENGTH = 6;
  inputs = new Array(this.OTP_LENGTH).fill(0).map((_, i) => i);

  readonly RESEND_TIME = 60000; // 1 minute

  wait_until = 0;
  wait_until_interval?: ReturnType<typeof setTimeout>;

  constructor(
    private dialogRef: MatDialogRef<OtpdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { error: string },
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.utils.sendEvent('pv_login_otp_modal');
    this.wait_until_interval = setInterval(() => {
      this.wait_until = Math.max(this.resendWait(), 0);
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.wait_until_interval) clearInterval(this.wait_until_interval);
  }

  getInputs(): Array<HTMLInputElement> {
    const otp_container = document.querySelector('.otp-container')!;
    return Array.from(otp_container.querySelectorAll('input')) as Array<HTMLInputElement>;
  }

  placeholderSet(target: EventTarget, show: boolean) {
    const element = target as HTMLInputElement;
    element.placeholder = show ? 'X' : '';
  }

  handleChange(event?: KeyboardEvent) {
    const otp_inputs = this.getInputs();

    if (event && event.key == 'Enter') {
      this.sendCode();
      return;
    }

    if (event && event.key == 'Backspace') {
      const otp_event_input = otp_inputs[otp_inputs.findIndex(input => input == event.target)];

      const otp_input = (event.target as HTMLInputElement).value == '' ? otp_inputs[otp_inputs.findIndex(input => input == event.target) - 1] : otp_event_input;

      if (!otp_input) return;

      otp_input.value = '';
    }

    for (const otp of otp_inputs) {
      const value = +otp.value.trim();
      if (isNaN(value)) otp.value = '';
      if (value > 9 || value < 0) otp.value = '';

      if (otp.value == '') {
        otp.focus();
        break;
      }
    }

    const otp = otp_inputs.map(input => input.value).join('');

    if (otp.length == this.OTP_LENGTH) {
      if (event) {
        setTimeout(() => {
          const target = event.target as HTMLInputElement;
          if (target) target.value = event.key;
        }, 10);
      }
    }
  }

  sendCode() {
    const code = this.getInputs()
      .map(input => input.value)
      .join('');
    if (code.length != this.OTP_LENGTH) return;
    if (isNaN(+code)) return;

    this.dialogRef.close({ confirm: true, value: code });
  }

  resend() {
    localStorage.setItem('otp_resend', new Date().getTime().toString());
    this.dialogRef.close('resend');
  }

  resendWait() {
    const otp_resend = localStorage.getItem('otp_resend');
    if (!otp_resend) return 0;

    const otp_resend_time = +otp_resend;
    const now = new Date().getTime();

    const wait_until = Math.floor((this.RESEND_TIME - (now - otp_resend_time)) / 1000);

    if (wait_until <= 0) localStorage.removeItem('otp_resend');
    return wait_until;
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any)['clipboardData'];
    const pastedData = clipboardData.getData('text') as string;
    const otp_container = document.querySelector('.otp-container')!;
    const otp_inputs = Array.from(otp_container.querySelectorAll('input')) as Array<HTMLInputElement>;

    if (pastedData.split('').some(e => isNaN(+e))) return;

    for (let i = 0; i < pastedData.length; i++) {
      otp_inputs[i].value = pastedData[i];
    }

    otp_inputs[this.OTP_LENGTH - 1].focus();
  }
}
