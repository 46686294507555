<div class="d-flex flex-column justify-content-center align-items-center">
  <img class="fut-emoji" src="../../../../assets/emoji/phone.png" />
  <h1 class="fut-h1 mt-1">Inserisci il codice di verifica</h1>
  <span class="text-center">Hai ricevuto il codice di {{ OTP_LENGTH }} cifre sul tuo numero di telefono</span>
  @if (data.error) {
    <span class="text-center text-danger">{{ data.error | transloco }}</span>
  }
  <div class="otp-container">
    @for (opt of inputs; track opt) {
      <input
        type="number"
        inputmode="numeric"
        maxlength="1"
        min="0"
        max="9"
        placeholder="X"
        (focus)="placeholderSet($event.target!, false)"
        (focusout)="placeholderSet($event.target!, true)"
        (focus)="handleChange()"
        (paste)="handlePaste($event)"
        (keydown)="handleChange($event)"
        (keyup)="handleChange()" />
    }
  </div>

  <div class="w-100 d-flex justify-content-end align-items-center">
    <button (click)="resend()" [disabled]="wait_until != 0" class="fut-btn fut-btn-link fut-bold mr-2">
      Invia di nuovo
      @if (wait_until != 0) {
        <span>({{ wait_until }})</span>
      }
    </button>
    <button (click)="sendCode()" class="fut-btn fut-btn-accent">Conferma</button>
  </div>
</div>
